export default class AnimationHandler {
  constructor() {
    this.displayedPage = 'page-landing';
  }

  startAnimate(id) {
    document.querySelectorAll('.animate').forEach(title => title.classList.remove('animate'));
    const currPage = document.querySelector(`#${id}`);
    currPage.querySelectorAll('.to-animate').forEach(title => title.classList.add('animate'));
  }
}