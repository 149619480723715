import AnimationHandler from './animations';

export default class Slider {
  constructor() {
    this.bookBtns = document.querySelectorAll('.book-nav-btn');
    this.slider = document.querySelector('.slider');
    this.closeBtn = document.querySelector('.slider__nav-btn-close');
    this.sliderBtnLeft = document.querySelector('.slider__nav-btn--left');
    this.sliderBtnRight = document.querySelector('.slider__nav-btn--right');
    this.animationHandler = new AnimationHandler();

    this.bookBtns.forEach(btn =>
      btn.addEventListener('click', () => {
        document.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '-1'));
        this.slider.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '0'));
        const slideId = btn.dataset.linkTo;
        const slide = document.querySelector(`.slide#${slideId}`);
        this.slider.classList.add('show');
        slide.classList.add('show');
        if (btn.dataset.originals === 'true') slide.classList.add('originals')
        this.animationHandler.startAnimate(slide.id);
      })
    );

    this.closeBtn.addEventListener('click', () => {
      const slide = document.querySelector('.slide.show');
      this.slider.classList.remove('show');
      slide.classList.remove('show');
      slide.classList.remove('originals');
      this.slider.scrollTo(0, 0);
      const currPage = document.querySelector('.page.show');
      this.animationHandler.startAnimate(currPage.id);
      currPage.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '0'));
    });

    this.sliderBtnLeft.addEventListener('click', event => {
      const slide = document.querySelector('.slide.show');
      const currSlideId = parseInt(slide.id.replace('synopsis-', ''));
      const newSlideId = currSlideId <= 1 ? 3 : currSlideId - 1;
      slide.classList.remove('show');
      const newSlide = document.querySelector(`.slide#synopsis-${newSlideId}`);
      newSlide.classList.add('show');
      this.slider.scrollTo(0, 0);
      this.animationHandler.startAnimate(newSlide.id);
    });

    this.sliderBtnRight.addEventListener('click', event => {
      const slide = document.querySelector('.slide.show');
      const currSlideId = parseInt(slide.id.replace('synopsis-', ''));
      const newSlideId = currSlideId >= 3 ? 1 : currSlideId + 1;
      slide.classList.remove('show');
      const newSlide = document.querySelector(`.slide#synopsis-${newSlideId}`);
      newSlide.classList.add('show');
      this.slider.scrollTo(0, 0);
      this.animationHandler.startAnimate(newSlide.id);
    });
  }
}
