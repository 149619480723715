import AnimationHandler from './animations';

export default class Navigation {
  constructor() {
    this.navBox = document.querySelector('.nav');
    this.btnNavLeft = document.querySelector("button[data-link-to='page-left']");
    this.btnNavRight = document.querySelector("button[data-link-to='page-right']");
    this.pageLeft = document.querySelector('.page--left');
    this.pageRight = document.querySelector('.page--right');
    this.mandala = document.querySelector('.bg-symbol');
    this.pageTitles = document.querySelectorAll('.page__title');
    this.animationHandler = new AnimationHandler();

    this.pageNavBtns = document.querySelectorAll('.page-nav-btn');

    this.revealLeftHandler = this.revealPage.bind(this, this.pageLeft);
    this.hideLeftHandler = this.hidePage.bind(this, this.pageLeft);
    this.revealRightHandler = this.revealPage.bind(this, this.pageRight);
    this.hideRightHandler = this.hidePage.bind(this, this.pageRight);

    this.btnNavLeft.addEventListener('mouseenter', this.revealLeftHandler);
    this.btnNavLeft.addEventListener('mouseleave', this.hideLeftHandler);
    this.btnNavRight.addEventListener('mouseenter', this.revealRightHandler);
    this.btnNavRight.addEventListener('mouseleave', this.hideRightHandler);

    this.btnNavLeft.addEventListener('click', () => {
      this.btnNavLeft.removeEventListener('mouseleave', this.hideLeftHandler);
      this.navBox.classList.add('hide');
      this.showPage(this.pageLeft);
    });
    this.btnNavRight.addEventListener('click', () => {
      this.btnNavRight.removeEventListener('mouseleave', this.hideRightHandler);
      this.navBox.classList.add('hide');
      this.showPage(this.pageRight);
    });

    this.pageNavBtns.forEach(btn => btn.addEventListener('click', () => {
      document.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '-1'));
      if (btn.dataset.linkTo === "page-landing") {
        this.reset();
      } else {
        this.navBox.classList.add('hide');
        const pageToHide = document.querySelector('.page.show');
        const pageToShow = document.querySelector(`#${btn.dataset.linkTo}`);
        pageToShow.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '0'));
        if (pageToHide) {
          this.hidePage(pageToHide);
        }
        this.showPage(pageToShow);
      }
    }))

    window.addEventListener('DOMContentLoaded', this.animationHandler.startAnimate('page-landing'));
    window.addEventListener('load', () => this.navBox.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '0')));
  }

  revealPage(page) {
    const spin = page.classList.contains('page--left') ? '90deg' : '-90deg';
    this.mandala.style.transform = `translate(-50%, -50%) rotate(${spin})`;
    page.classList.add('reveal');
    this.pageTitles.forEach(title => title.style.animationPlayState = 'paused');
  }

  showPage(page) {
    const spin = page.classList.contains('page--left') ? '180deg' : '-180deg';
    this.mandala.style.transform = `translate(-50%, -50%) rotate(${spin})`;
    page.classList.remove('reveal');
    page.classList.add('show');
    page.scrollTo(0, 0);
    this.animationHandler.startAnimate(page.id);
    this.pageTitles.forEach(title => title.style.animationPlayState = 'running');
    setTimeout(() => {
      this.showPageContent(page);
      this.hideMandala();
    }, 500);
  }

  showPageContent(page) {
    page.querySelector('.page__content').classList.add('show');
    page.querySelector('.navbar').classList.add('show');
  }

  hidePageContent(page) {
    page.querySelector('.page__content').classList.remove('show');
    page.querySelector('.navbar').classList.remove('show');
  }

  hidePage(page) {
    this.mandala.style.transform = 'translate(-50%, -50%) rotate(0)';
    page.classList.remove('reveal');
    page.classList.remove('show');
    this.pageTitles.forEach(title => title.style.animationPlayState = 'running');
  }

  hideMandala() {
    this.mandala.classList.add('hide');
  }

  showMandala() {
    this.mandala.classList.remove('hide');
  }

  reset() {
    this.animationHandler.startAnimate('page-landing');
    this.navBox.querySelectorAll('.tab').forEach(btn => btn.setAttribute('tabindex', '0'));
    this.hidePage(this.pageLeft);
    this.hidePageContent(this.pageLeft);
    this.hidePage(this.pageRight);
    this.hidePageContent(this.pageRight);
    this.pageLeft.scrollTo(0, 0);
    this.pageRight.scrollTo(0, 0);
    this.btnNavLeft.addEventListener('mouseenter', this.revealLeftHandler);
    this.btnNavLeft.addEventListener('mouseleave', this.hideLeftHandler);
    this.btnNavRight.addEventListener('mouseenter', this.revealRightHandler);
    this.btnNavRight.addEventListener('mouseleave', this.hideRightHandler);
    this.navBox.classList.remove('hide');
    this.showMandala();
  }
}
